<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="py-4">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Code") }} </label>
                <v-text-field
                  v-model="editedItem.code"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50
                  ]"
                  @focus="onFocus()"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.code">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.code"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }} </label>
                <v-text-field
                  v-model="editedItem.en.name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength100
                  ]"
                  @focus="onFocus()"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.en">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.en.name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Arabic Name") }}</label>
                <v-text-field
                  v-model="editedItem.ar.name"
                  solo
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength100,
                    CheckArabicCharactersOnly(editedItem.ar.name)
                  ]"
                ></v-text-field>
                <div v-if="validation_errors.ar">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.ar.name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Type") }} </label>
                <v-autocomplete
                  :items="types"
                  v-model="editedItem.account_type_id"
                  item-text="name"
                  item-value="id"
                  :rules="[validationRules.required]"
                  @focus="onFocus()"
                  solo
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Notes") }} </label>
                <v-textarea
                  v-model="editedItem.notes"
                  solo
                  :rules="[validationRules.maxLength1000]"
                  rows="3"
                ></v-textarea>
                <div v-if="validation_errors.notes">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.notes"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints"
  ],
  mixins: [validationMixin],
  name: "AddEditAccountComponent",
  data() {
    return {
      valid: false,
      types: [],
      validation_errors: {
        en: {
          name: ""
        },
        ar: {
          name: ""
        },
        code: ""
      },
      loading: false,
      disabledSubmitbtn: false
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  methods: {
    close() {
      this.AddEditdialog = false;
      this.editedItem = {};
      this.validation_errors = {
        en: {
          name: ""
        },
        ar: {
          name: ""
        }
      };
      this.$emit("childToParent", false, null, this.editedIndex);
    },
    onFocus() {
      this.validation_errors = {
        name: ""
      };
    },
    getTypes() {
      axios
        .get(
          this.getApiUrl + "/accounts/getTypes",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.types = response.data.data;
        });
    },
    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                // location.reload();
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.disabledSubmitbtn = true;
                this.editedItem = {
                  en: {
                    name: ""
                  },
                  ar: {
                    name: ""
                  }
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    this.getTypes();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-name {
  margin-top: -15px !important;
}
</style>
